import { useTranslation } from "next-i18next";
import { FC, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Trans from "~/types/translationKeys";
import styles from "./DemoVideo.module.scss";
import { motion } from "framer-motion";

const DemoVideo: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref, inView } = useInView({ threshold: 0.7 });
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [inView]);

  return (
    <motion.section
      layout
      id="how-it-work"
      ref={ref}
      className={styles.demo_video}
    >
      <h2 className="!mb-8 section-title">{t(Trans.demo_video_title)}</h2>
      <motion.div
        layout
        className={styles.video_container}
        style={{ maxWidth: !inView ? 1240 : 1240 }}
      >
        <video
          ref={videoRef}
          className={styles.video}
          width="100%"
          controls
          muted
          loop
          playsInline
          preload="auto"
        >
          <source
            src="https://storage.cxgenie.ai/landing/demo-09-09-2024.mp4"
            type="video/mp4"
          />
          {t(Trans.your_browser_does_not_support_the_video_tag)}
        </video>
      </motion.div>
    </motion.section>
  );
};
export default DemoVideo;
