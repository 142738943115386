import classNames from "classnames";
import { i18n, useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC, Fragment } from "react";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import { features } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./Features.module.scss";
import Text from "~/components/Text";

interface Feature {
  icon?: string;
  name: string;
  link?: string;
  href?: string;
  width?: number;
}

const FeatureItem = ({ feature }: { feature: Feature[] }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.feature_item}>
      <div className={styles.right}>
        {feature.map((item, index) => (
          <div className={styles.item} key={item.name}>
            {item.icon && (
              <Image
                src={item.icon}
                alt={item.name}
                width={item.width}
                height={20}
              />
            )}
            <p className={styles.name}>
              {t(item.name)}
              {index !== feature.length - 1 && ", "}
            </p>
            {item.href && (
              <Link target="_blank" href={item.href} className={styles.link}>
                {item.link}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Features: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="features" className={styles.features}>
      <Container>
        <div className="flex flex-col items-center gap-3">
          <h2 className="section-title !mb-0 max-w-[500px]" data-aos="fade-up">
            {t(Trans.solution_title)}
          </h2>

          <p className={styles.description} data-aos="fade-up">
            {t(Trans.solution_desc)}
          </p>
        </div>

        <div
          className="grid grid-cols-1 gap-6 lg:grid-cols-3 md:grid-cols-2"
          data-aos="fade-up"
        >
          {features.map((item, index) => (
            <Link className="h-full" key={item.title} href={item.url}>
              <div
                className="h-full"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 100}
              >
                <div
                  className={classNames(
                    styles.card,
                    styles[`card_${index + 1}`]
                  )}
                >
                  <div
                    className={classNames(
                      styles.card_image,
                      "relative w-full aspect-[397/300] max-w-[590px]"
                    )}
                  >
                    <Image
                      className="object-cover"
                      src={item.thumb}
                      alt={item.title}
                      fill
                    />
                  </div>
                  <div className="flex flex-col gap-2 p-5">
                    <Image
                      src={item.icon}
                      alt={item.title}
                      width={48}
                      height={48}
                    />
                    <h3 className={styles.card_title}>{t(item.title)}</h3>
                    <p className={styles.card_description}>
                      {t(item.description)}
                    </p>
                    <div className={styles.learn_more}>
                      <Icon className={styles.icon} name="seemore" />
                      <span className={styles.content}>
                        {t(Trans.learn_more)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div data-aos="fade-up" className={styles.integration}>
          <div className="relative aspect-[535/436] flex-1 max-w-[535px]">
            <Image
              className="object-contain"
              src="/svgs/feature_integration.svg"
              alt="Feature Integration"
              fill
            />
          </div>
          <div className="flex flex-col flex-1 gap-3">
            <h1 className={styles.integration_title}>
              {t(Trans.integration_title)}
            </h1>
            <p className={styles.integration_desc}>
              {t(Trans.integration_desc)}
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Features;
