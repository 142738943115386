import axios from "axios";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import { FC, Fragment } from "react";
import Brand from "~/components/HomeSections/Brand";
import CustomerFeedback from "~/components/HomeSections/CustomerFeedback";
import DemoVideo from "~/components/HomeSections/DemoVideo";
import Discord from "~/components/HomeSections/Discord";
import DiscordModal from "~/components/HomeSections/DiscordModal";
import Experience from "~/components/HomeSections/Experience";
import Features from "~/components/HomeSections/Features";
import Hero from "~/components/HomeSections/Hero";
import Integrations from "~/components/HomeSections/Integrations";
import KeyFeatures from "~/components/HomeSections/KeyFeatures";
import Pricing from "~/components/HomeSections/Pricing";
import { Package } from "~/components/HomeSections/Pricing/Pricing";
const Questions = dynamic(() => import("~/components/HomeSections/Questions"), {
  ssr: false,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const { data } = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/packages`
    );
    const packageData = data?.data
      ?.sort((a: any, b: any) => a.order - b.order)
      .filter(
        (p: any) =>
          !["ONE_TIME", "ONE_TIME_T1", "ONE_TIME_T2", "ONE_TIME_T3"].includes(
            p.type
          )
      );

    return {
      props: {
        packages: packageData,
        ...(await serverSideTranslations(context.locale || "en", ["common"])),
      },
    };
  } catch (e) {
    return {
      props: {
        packages: [],
        ...(await serverSideTranslations(context.locale || "en", ["common"])),
      },
    };
  }
};

const Home: FC<{ packages: Package[] }> = ({ packages }) => {
  return (
    <Fragment>
      <Hero />
      {/* <ProductHuntBanner /> */}
      <DemoVideo />
      {/* <AudienceTarget /> */}
      <KeyFeatures />
      <Integrations />
      <Brand />

      <Features />
      <Pricing packages={packages} />
      <CustomerFeedback />
      <Questions />
      <Discord />
      <DiscordModal />
      <Experience />
    </Fragment>
  );
};

export default Home;
